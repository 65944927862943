import React, { useState } from "react";
import { Button, Modal, Alert } from "react-bootstrap";
import QrReader from "react-qr-reader";

const ControlloQrCode = (props) => {
  const [codiceErrato, setCodiceErrato] = useState(false);

  function handleScan(data) {
    console.log("qr scansito: " + data);

    ///solo per sviluppo //////////////////////
    //props.handleSubmit();
    ///solo per sviluppo /////////////////////

    if (data) {
      //setCodiceInserito(data);
      if (data === props.controllo.codice) {
        props.handleSubmit();
      } else if (data === null) {
        console.log("none");
      } else {
        setCodiceErrato(true);
      }
    }
  }

  function handleError(err) {
    console.error(err);
  }

  return (
    <Modal show={props.show} onHide={props.handleClose}>
      <Modal.Header>
        <Modal.Title>{props.controllo.nome}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Inquadra il QR Code che trovi sull'apposito cartello al controllo
        <QrReader
          className="my-3"
          delay={300}
          onError={handleError}
          onScan={handleScan}
          style={{ width: "100%" }}
        />
        {codiceErrato && (
          <Alert variant="danger">
            Codice errato, verifica di aver selezionato il controllo corretto.
          </Alert>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.handleClose}>
          Annulla
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ControlloQrCode;
