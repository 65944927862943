import React from "react";
import Moment from "react-moment";
import { formatoData } from "../Util/date";

const DataCustom = (props) => {
  return (
    <div>
      <Moment format={formatoData}>{props.data}</Moment>
      &nbsp;
      <small>
        (<Moment fromNow>{props.data}</Moment>)
      </small>
    </div>
  );
};

export default DataCustom;
