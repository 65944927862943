import React, { useEffect, useState } from "react";
import { Table, Container } from "react-bootstrap";
import { db } from "../Util/db";
import ListaControlliAdmin from "./ListaControlliAdmin";
import QRControllo from "./QRControllo";

const GestioneTappe = () => {
  const [tappe, setTappe] = useState([]);
  useEffect(() => {
    db.ref()
      .child("config/tappe")
      .get()
      .then((data) => {
        if (data.exists()) {
          //console.log(data.val());
          setTappe(data.val());
        } else {
          console.log("No data available - tappe");
          setTappe([]);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, [tappe]);
  return (
    <Container className="maincontainer">
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>indice Tappa</th>
            <th>Nome Tappe</th>
            <th>Partenza</th>
            <th>Controlli</th>
            <th>Arrivo</th>
          </tr>
          {tappe.map((tappa, indice) => (
            <tr key={indice}>
              <td>{indice}</td>
              <td>{tappa.nome}</td>
              <td>
                <QRControllo
                  indice={0}
                  controllo={tappa.partenza}
                  indiceTappa={indice}
                ></QRControllo>
              </td>
              <td>
                <ListaControlliAdmin indiceTappa={indice}></ListaControlliAdmin>
              </td>
              <td>
                {" "}
                <QRControllo
                  indice={0}
                  controllo={tappa.arrivo}
                  indiceTappa={indice}
                ></QRControllo>
              </td>
            </tr>
          ))}
        </thead>
        <tbody></tbody>
      </Table>
    </Container>
  );
};

export default GestioneTappe;
