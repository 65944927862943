import React, { Fragment, useState } from "react";
import { Button, Modal } from "react-bootstrap";

const ConcorrenteRitirato = (props) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <Fragment>
      <Button className="mt-2" variant="danger" onClick={handleShow}>
        Concorrente Ritirato
      </Button>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Concorrente Ritirato</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Vuoi procedere con il ritiro di {props.concorrente.nome}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Annulla
          </Button>
          <Button
            variant="primary"
            onClick={(indice) => props.concorrenteRitirato(props.indice)}
          >
            Conferma
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default ConcorrenteRitirato;
