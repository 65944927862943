import React, { useState } from "react";
import { Button } from "react-bootstrap";
import ModalModificaConcorrente from "./ModalModificaConcorrente";
import ModalModificaTappeConcorrente from "./ModalModificaTappeConcorrente";
import UltimoControllo from "./UltimoControllo";
import classNames from "classnames";

const RigaConcorrente = (props) => {
  const [show, setShow] = useState(false);
  const [showTappe, setShowTappe] = useState(false);

  const handleShow = () => setShow(true);
  const handleShowTappe = () => setShowTappe(true);
  const chiudiPopup = () => setShow(false);
  const chiudiPopupTappe = () => setShowTappe(false);

  function salvaConcorrente(indiceConcorrente) {
    chiudiPopup();
    chiudiPopupTappe();
    props.salvaConcorrente(indiceConcorrente);
  }

  function getUrlFunction() {
    if (window.location.hostname === "localhost") {
      return (
        "http://localhost:5001/veb-bike/europe-west1/stampaDiploma?id=" +
        props.indice
      );
    } else {
      return (
        "https://europe-west1-veb-bike.cloudfunctions.net/stampaDiploma?id=" +
        props.indice
      );
    }
  }

  return (
    <tr
      key={props.indice}
      className={classNames({
        ritirato: props.concorrente.concorrenteRitirato,
        completo: props.concorrente.brevettoCompletato,
      })}
    >
      <td>{props.concorrente.numero}</td>
      <td>{props.concorrente.nome}</td>
      <td>{props.concorrente.anno}</td>
      <td>
        <UltimoControllo indiceConcorrente={props.indice}></UltimoControllo>
      </td>
      <td>
        <Button onClick={handleShow}>Modifica</Button>&nbsp;
        <Button onClick={handleShowTappe}>Tappe</Button> &nbsp;
        {props.concorrente.brevettoCompleto ? (
          <Button target="_blank" rel="noreferrer" href={getUrlFunction()}>
            Stampa Diploma
          </Button>
        ) : (
          ""
        )}
      </td>
      <ModalModificaConcorrente
        key={props.indice + 1}
        indice={props.indice}
        concorrente={props.concorrente}
        show={show}
        handleClose={chiudiPopup}
        handleChange={props.handleChange}
        salvaConcorrente={salvaConcorrente}
        brevettoCompletato={props.brevettoCompletato}
        concorrenteRitirato={props.concorrenteRitirato}
      ></ModalModificaConcorrente>
      <ModalModificaTappeConcorrente
        key={props.indice + 2}
        indice={props.indice}
        concorrente={props.concorrente}
        show={showTappe}
        handleClose={chiudiPopupTappe}
        aggiungiPassaggio={props.aggiungiPassaggio}
        handleChangeControllo={props.handleChangeControllo}
        salvaConcorrente={salvaConcorrente}
      ></ModalModificaTappeConcorrente>
    </tr>
  );
};

export default RigaConcorrente;
