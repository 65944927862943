import React from "react";
import moment from "moment";
import "moment/locale/it";
import MomentInput from "react-moment-input";
import { Button } from "react-bootstrap";

const ModificaControllo = (props) => {
  return props.orarioPassaggio === 0 ? (
    <div>
      <Button
        onClick={(
          indiceT,
          indiceC,
          indiceControllo,
          tipo,
          nomeControllo,
          nomeTappa
        ) =>
          props.aggiungiPassaggio(
            props.indiceTappa,
            props.indiceConcorrente,
            props.indiceControllo,
            props.tipo,
            props.nomeControllo,
            props.nomeTappa
          )
        }
      >
        Aggiungi
      </Button>
    </div>
  ) : (
    <MomentInput
      locale="it"
      format="YYYY-MM-DD HH:mm"
      options={true}
      today={false}
      readOnly={false}
      showIcon={true}
      autoClose={true}
      translations={{
        DATE: "Data",
        TIME: "Ora",
        HOURS: "ore",
        MINUTES: "minuti",
        TODAY: "oggi",
      }}
      daysOfWeek={["Mar", "Mer", "Gio", "Ven", "Sab", "Dom", "Lun"]}
      enableInputClick={true}
      //style={props.orarioPassaggio === 0 ? "none" : "passato"}
      //defaultValue={moment(props.orarioPassaggio)}
      defaultValue={moment(props.orarioPassaggio)}
      onChange={(
        data,
        name,
        indiceT,
        indiceC,
        indiceControllo,
        tipo,
        nomeControllo
      ) =>
        props.handleChangeControllo(
          data,
          name,
          props.indiceTappa,
          props.indiceConcorrente,
          props.indiceControllo,
          props.tipo,
          props.nomeControllo
        )
      }
    />
  );
};

export default ModificaControllo;
