import React, { useState, useEffect } from "react";
import { Card, Button, Row, Col, Alert } from "react-bootstrap";
import { db } from "../Util/db";
import moment from "moment";
import { salvaDatiControllo } from "../Util/controllo";
import DataCustom from "./DataCustom";
import ControlloQrCode from "./ControlloQrCode";
import { Fragment } from "react";

function Controllo(props) {
  const [controllo, setControllo] = useState(0);
  const [passaggio, setPassaggio] = useState(0);
  const [label, setLabel] = useState("Controllo");
  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);
  const chiudiPopup = () => setShow(false);

  function salvaControllo() {
    const data = new Date();

    //salvo controllo in db
    salvaDatiControllo(
      props.tipo,
      props.indicetappa,
      props.indicecontrollo,
      props.concorrente,
      data,
      props.nometappa,
      controllo.nome
    );
    setPassaggio({ orarioPassaggio: data });
    setShow(false);
    props.passaggio();
  }

  useEffect(() => {
    //carico dati partenza
    //se ho appena caricato la pagina carico i dati partenza
    let query = "";
    let queryPassaggio = "";

    switch (props.tipo) {
      case "partenza":
        query = "config/tappe/" + props.indicetappa + "/partenza";
        queryPassaggio =
          "/concorrenti/" +
          props.concorrente +
          "/tappe/" +
          props.indicetappa +
          "/partenza";
        setLabel("Partenza");
        break;
      case "arrivo":
        query = "config/tappe/" + props.indicetappa + "/arrivo";
        queryPassaggio =
          "/concorrenti/" +
          props.concorrente +
          "/tappe/" +
          props.indicetappa +
          "/arrivo";
        setLabel("Arrivo");
        break;
      case "controllo":
        query =
          "config/tappe/" +
          props.indicetappa +
          "/controlli/" +
          props.indicecontrollo;
        queryPassaggio =
          "/concorrenti/" +
          props.concorrente +
          "/tappe/" +
          props.indicetappa +
          "/controlli/" +
          props.indicecontrollo;
        setLabel("Controllo");
        break;
      default:
    }

    //estraggo i dati del controllo dal db
    if (controllo === 0) {
      db.ref()
        .child(query)
        .get()
        .then((data) => {
          if (data.exists()) {
            setControllo(data.val());
            //console.log("ctrl " + controllo);
          } else {
            console.log("No data available");
          }
        })
        .catch((error) => {
          console.error(error);
        });

      //estraggo anche i dati del passaggio del concorrente, se ci sono
      db.ref()
        .child(queryPassaggio)
        .get()
        .then((data) => {
          if (data.exists()) {
            setPassaggio(data.val());
            //console.log(data.val());
          } else {
            console.log("No data available - passaggio");
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [
    setLabel,
    controllo,
    props.concorrente,
    props.indicecontrollo,
    props.indicetappa,
    props.tipo,
  ]);

  //verifica se la partenza è aperta
  function verificaDateApertura(dataApertura, dataChiusura) {
    let dataApMoment = new moment(dataApertura);
    let dataChMoment = new moment(dataChiusura);

    let differenzaApertura = moment
      .duration(moment(dataApMoment.diff(new Date())))
      .asSeconds();
    let differenzaChiusura = moment
      .duration(moment(dataChMoment.diff(new Date())))
      .asSeconds();

    //console.log("ap" + differenzaApertura);
    //console.log("ch" + differenzaChiusura);
    if (differenzaApertura < 0 && differenzaChiusura > 0) {
      return (
        <Button size="lg" variant="primary" onClick={handleShow}>
          {label}!
        </Button>
      );
    } else {
      return <Alert variant="danger">{label} Chiuso</Alert>;
    }
  }

  if (controllo) {
    //console.log(controllo);
    return (
      <Fragment>
        <Card className={"py-3 px-3 mx-3 my-3 " + props.tipo}>
          <Card.Title>{controllo.nome}</Card.Title>
          <Row>
            <Col sm={6}>
              Apertura {label}
              <br></br>
              <b>
                <DataCustom
                  data={controllo.orari.aperturaufficiale}
                ></DataCustom>
              </b>
            </Col>
            <Col sm={6}>
              Chiusura {label}
              <br></br>
              <b>
                <DataCustom
                  data={controllo.orari.chiusuraufficiale}
                ></DataCustom>
              </b>
            </Col>
          </Row>
          <hr></hr>
          <Row>
            <Col sm={3}></Col>
            <Col sm={6}>
              {passaggio.orarioPassaggio ? (
                <Alert variant="success">
                  Transitato il :
                  <DataCustom data={passaggio.orarioPassaggio}></DataCustom>
                </Alert>
              ) : (
                verificaDateApertura(
                  controllo.orari.apertura,
                  controllo.orari.chiusura
                )
              )}
            </Col>
            <Col sm={3}></Col>
          </Row>
        </Card>
        <ControlloQrCode
          controllo={controllo}
          show={show}
          handleClose={chiudiPopup}
          handleSubmit={salvaControllo}
        ></ControlloQrCode>
      </Fragment>
    );
  } else {
    return "loading";
  }
}

export default Controllo;
