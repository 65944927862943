import React, { Component } from "react";
import AdminPage from "./AdminPage";
import LoginAdmin from "./LoginAdmin";
import { auth } from "../Util/db";

class MainAdmin extends Component {
  state = {
    user: {
      autenticato: false,
    },
  };

  componentDidMount() {
    auth.onAuthStateChanged((userAuth) => {
      if (userAuth && userAuth.email) {
        //console.log("autenticato : ", userAuth);
        this.setState({
          user: {
            autenticato: true,
            uid: userAuth.uid,
            email: userAuth.email,
            nomeUtente: userAuth.displayName
              ? userAuth.displayName
              : userAuth.email,
          },
          loading: false,
        });
      } else {
        this.setState({
          user: {
            autenticato: false,
          },
          loading: false,
        });
      }
    });
  }

  render() {
    return this.state.loading ? (
      "loading"
    ) : this.state.user.autenticato ? (
      <AdminPage user={this.state.user} />
    ) : (
      <LoginAdmin />
    );
  }
}

export default MainAdmin;
