import { db } from "./db";

export function getConcorrente(indice) {
  db.ref()
    .child("/concorrenti/" + indice)
    .get()
    .then((data) => {
      if (data.exists()) {
        console.log(data.val());
        return data.val();
      } else {
        console.log("No data available - concorrente");
      }
    })
    .catch((error) => {
      console.error(error);
    });
}

export function getProssimoNumero(concorrenti) {
  let prossimoNumero = -1;

  //filtro elementi vuoti
  let concorrentiNuovo = concorrenti.filter((el) => {
    return el != null && el !== "";
  });

  prossimoNumero = Math.max.apply(
    Math,
    concorrentiNuovo.map(function (o) {
      return o.numero;
    })
  );
  console.log("prossimo numero", prossimoNumero);
  if (prossimoNumero) {
    return prossimoNumero + 1;
  } else {
    return 999;
  }
}
