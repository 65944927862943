import React, { useState, useEffect } from "react";
import { Card, Badge } from "react-bootstrap";
import Clock from "react-digital-clock";
import Moment from "react-moment";
import moment from "moment";

const Orologio = () => {
  const [data, setData] = useState(moment());

  //come CDM

  useEffect(() => {
    //console.log(moment().format("DD-MM-YYYY hh:mm:ss"));
    setData(moment());
    //console.log(data);
  }, []);

  return (
    <Card className="px-3 py-3 mb-2">
      <Card.Title>Data e Ora</Card.Title>
      <Card.Body>
        <h2>
          <Badge variant="primary">
            <Moment format="DD MMMM YYYY">{data}</Moment>
            <Clock className="mt-2" hour12={false} />
          </Badge>
        </h2>
      </Card.Body>
    </Card>
  );
};

export default Orologio;
