import React from "react";
import { Form, Card, Row, Col, Button } from "react-bootstrap";

const RicercaConcorrente = (props) => {
  return (
    <Card className="py-5 px-5">
      <Row>
        <Col>
          {/* <Form>
            <Form.Control
              onChange={props.cercaConcorrente}
              placeholder="Cerca per Nome e Cognome"
            />
          </Form> */}
        </Col>
        <Col>
          <Button onClick={props.aggiungiConcorrente}>
            Aggiungi Concorrente
          </Button>
        </Col>
      </Row>
    </Card>
  );
};

export default RicercaConcorrente;
