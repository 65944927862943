import firebase from "firebase";

const firebaseConfig = {
  apiKey: "AIzaSyCNRc-WSoW8bPWruSevaHCDzVEgt2oBS5s",
  authDomain: "veb-bike.firebaseapp.com",
  databaseURL:
    "https://veb-bike-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "veb-bike",
  storageBucket: "veb-bike.appspot.com",
  messagingSenderId: "813460705028",
  appId: "1:813460705028:web:15f50b23167686e49a4a51",
  measurementId: "G-0QSNLH5RC0",
};

firebase.initializeApp(firebaseConfig);
var db = firebase.database();
if (window.location.hostname === "localhost") {
  db.useEmulator("localhost", 9000);
}

export const auth = firebase.auth();

export { db };
