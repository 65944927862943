import React, { useEffect, useState } from "react";
import { Table, Container } from "react-bootstrap";
import { db } from "../Util/db";
import { getProssimoNumero } from "../Util/user";
import ModalModificaConcorrente from "./ModalModificaConcorrente";
import RicercaConcorrente from "./RicercaConcorrente";
import RigaConcorrente from "./RigaConcorrente";
import moment from "moment";

const TabellaConcorrenti = (props) => {
  const [concorrenti, setConcorrenti] = useState([]);
  const [nomeConcorrente, setNomeConcorrente] = useState(" ");
  const [nuovoConcorrente, setNuovoConcorrente] = useState({});
  const [indiceNuovoConcorrente, setIndiceNuovoConcorrente] = useState({});
  const [show, setShow] = useState(false);

  const chiudiPopup = () => setShow(false);

  useEffect(() => {
    db.ref("concorrenti/").on("value", (data) => {
      if (data.exists()) {
        //console.log(data.val());
        let concorrentiN = [];
        data.forEach(function (concorrente) {
          //console.log(concorrente.key);
          let concorrenteN = concorrente.val();
          concorrenteN.indice = concorrente.key;
          concorrentiN.push(concorrenteN);
        });
        setConcorrenti(concorrentiN);
      } else {
        console.log("No data available - concorrenti");
        setConcorrenti([]);
      }
    });
  }, []);

  function aggiungiConcorrente() {
    console.log(concorrenti);

    //creo un nuovo oggetto e lo inserisco nello state
    let nuovoConcorrente = {
      nome: "",
      numero: getProssimoNumero(concorrenti),
      anno: "",
    };

    console.log(nuovoConcorrente);
    setNuovoConcorrente(nuovoConcorrente);

    let concorrentiNuovo = [
      ...concorrenti.filter((el) => {
        return el != null && el !== "";
      }),
    ];
    let indiceNuovo = concorrentiNuovo.push(nuovoConcorrente) - 1;

    setIndiceNuovoConcorrente(indiceNuovo);

    setConcorrenti(concorrentiNuovo);
    setShow(true);
  }

  function cercaConcorrente(e) {
    setNomeConcorrente(e.target.value.toUpperCase());
  }

  function salvaConcorrente(indice) {
    console.log("salvaconcorrente _ p", indice);
    console.log(concorrenti[indice]);
    db.ref("/concorrenti/" + indice).set(concorrenti[indice]);
    setShow(false);
  }

  function handleChange(e, indice) {
    const concorrentiN = [...concorrenti];
    //console.log(concorrentiN[indice]);
    concorrentiN[indice][e.target.name] = e.target.value;
    setConcorrenti(concorrentiN);
  }

  function handleChangeControllo(
    data,
    nome,
    indiceTappa,
    indiceConcorrente,
    indiceControllo,
    tipoPassaggio,
    nomeControllo
  ) {
    //console.log(tipoPassaggio);
    //return;
    const concorrentiN = [...concorrenti];

    if (tipoPassaggio === "controllo") {
      concorrentiN[indiceConcorrente].tappe[indiceTappa].controlli[
        indiceControllo
      ].orarioPassaggio = data.toISOString();
      concorrentiN[indiceConcorrente].tappe[indiceTappa].controlli[
        indiceControllo
      ].nomeControllo = nomeControllo;
    } else {
      concorrentiN[indiceConcorrente].tappe[indiceTappa][
        tipoPassaggio
      ].orarioPassaggio = data.toISOString();
      concorrentiN[indiceConcorrente].tappe[indiceTappa][
        tipoPassaggio
      ].nomeControllo = nomeControllo;
    }
    //concorrentiN[indice].tappe[indicetappa] = e.target.value;
    setConcorrenti(concorrentiN);
  }

  //aggiunge un passaggio allo state se non esiste
  function aggiungiPassaggio(
    indiceTappa,
    indiceConcorrente,
    indiceControllo,
    tipoPassaggio,
    nomeControllo,
    nomeTappa
  ) {
    console.log(
      "nuovo passaggio non registrato",
      indiceTappa,
      indiceConcorrente,
      indiceControllo,
      tipoPassaggio,
      nomeTappa
    );

    const concorrentiN = [...concorrenti];

    let data = moment().toISOString();

    //creo il nodo tappe se non esiste
    if (!concorrentiN[indiceConcorrente].tappe) {
      console.log("creo nodo tappe");
      concorrentiN[indiceConcorrente].tappe = [];
    }
    //creo il nodo tappa se non esiste e aggiungo il nome
    if (!concorrentiN[indiceConcorrente].tappe[indiceTappa]) {
      console.log("creo tappa");
      concorrentiN[indiceConcorrente].tappe[indiceTappa] = {};
      concorrentiN[indiceConcorrente].tappe[indiceTappa].nome = nomeTappa;
    }
    //creo il nodo controlli se non esiste

    if (
      tipoPassaggio === "controllo" &&
      !concorrentiN[indiceConcorrente].tappe[indiceTappa].controlli
    ) {
      console.log("creo controlli");
      concorrentiN[indiceConcorrente].tappe[indiceTappa].controlli = [];
    }

    //creo il nodo controllo se non esiste
    if (
      tipoPassaggio === "controllo" &&
      !concorrentiN[indiceConcorrente].tappe[indiceTappa].controlli[
        indiceControllo
      ]
    ) {
      console.log("creo controllo");
      concorrentiN[indiceConcorrente].tappe[indiceTappa].controlli[
        indiceControllo
      ] = {};
    }

    //poi vado a creare il nodo
    if (tipoPassaggio === "controllo") {
      concorrentiN[indiceConcorrente].tappe[indiceTappa].controlli[
        indiceControllo
      ].orarioPassaggio = data;
      concorrentiN[indiceConcorrente].tappe[indiceTappa].controlli[
        indiceControllo
      ].nomeControllo = nomeControllo;
    } else {
      concorrentiN[indiceConcorrente].tappe[indiceTappa][tipoPassaggio] = {};
      concorrentiN[indiceConcorrente].tappe[indiceTappa][
        tipoPassaggio
      ].orarioPassaggio = data;
      concorrentiN[indiceConcorrente].tappe[indiceTappa][
        tipoPassaggio
      ].nomeControllo = nomeControllo;
    }

    //setto lo stato
    setConcorrenti(concorrentiN);
  }

  function brevettoCompletato(indice) {
    console.log("brevetto completato per conc", indice);
    const concorrentiN = [...concorrenti];
    console.log(concorrentiN[indice]);
    concorrentiN[indice].brevettoCompleto = true;
    setConcorrenti(concorrentiN);
    db.ref("/concorrenti/" + indice).set(concorrenti[indice]);
  }

  function concorrenteRitirato(indice) {
    console.log("concorrente ritirato", indice);
    const concorrentiN = [...concorrenti];
    console.log(concorrentiN[indice]);
    concorrentiN[indice].concorrenteRitirato = true;
    setConcorrenti(concorrentiN);
    db.ref("/concorrenti/" + indice).set(concorrenti[indice]);
  }

  return (
    <Container className="maincontainer">
      <RicercaConcorrente
        nomeConcorrente={nomeConcorrente}
        cercaConcorrente={(e) => cercaConcorrente(e)}
        aggiungiConcorrente={() => aggiungiConcorrente()}
      ></RicercaConcorrente>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Numero</th>
            <th>Nome e Cognome</th>
            <th>Data di Nascita</th>
            <th>Ultimo Controllo</th>
            <th>Azioni</th>
          </tr>
        </thead>
        <tbody>
          {concorrenti.map((concorrente) => (
            <RigaConcorrente
              key={concorrente.indice}
              indice={concorrente.indice}
              concorrente={concorrente}
              salvaConcorrente={salvaConcorrente}
              brevettoCompletato={(indice) => brevettoCompletato(indice)}
              handleChange={(e, indice) => handleChange(e, indice)}
              handleChangeControllo={(
                val,
                nome,
                indiceTappa,
                indiceConcorrente,
                indiceControllo,
                tipo,
                nomeControllo
              ) =>
                handleChangeControllo(
                  val,
                  nome,
                  indiceTappa,
                  indiceConcorrente,
                  indiceControllo,
                  tipo,
                  nomeControllo
                )
              }
              aggiungiPassaggio={(
                indiceTappa,
                indiceConcorrente,
                indiceControllo,
                tipo,
                nomeControllo,
                nomeTappa
              ) =>
                aggiungiPassaggio(
                  indiceTappa,
                  indiceConcorrente,
                  indiceControllo,
                  tipo,
                  nomeControllo,
                  nomeTappa
                )
              }
              concorrenteRitirato={(indice) => concorrenteRitirato(indice)}
            ></RigaConcorrente>
          ))}
        </tbody>
      </Table>
      <ModalModificaConcorrente
        key={indiceNuovoConcorrente}
        indice={indiceNuovoConcorrente}
        concorrente={nuovoConcorrente}
        show={show}
        handleClose={chiudiPopup}
        handleChange={(e, indice) => handleChange(e, indice)}
        salvaConcorrente={salvaConcorrente}
      ></ModalModificaConcorrente>
    </Container>
  );
};

export default TabellaConcorrenti;
