import React from "react";
import { Accordion, Card, Button } from "react-bootstrap";
var QRCode = require("qrcode.react");

const QRControllo = (props) => {
  return (
    <Accordion>
      <Card>
        <Card.Header>
          <Accordion.Toggle
            as={Button}
            variant="link"
            eventKey={props.indice + props.indiceTappa + 1}
          >
            {props.controllo.nome}
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey={props.indice + props.indiceTappa + 1}>
          <Card.Body>
            <QRCode
              id={props.indice}
              value={props.controllo.codice}
              renderAs="svg"
              size={290}
              level={"H"}
              includeMargin={true}
            ></QRCode>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
};

export default QRControllo;
