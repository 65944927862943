import React from "react";
import { Button, Modal, Form, Col, Row, Badge } from "react-bootstrap";
import ConcorrenteRitirato from "./ConcorrenteRitirato";
import BrevettoCompletato from "./BrevettoCompletato";

const ModalModificaConcorrente = (props) => {
  return (
    <Modal size="lg" show={props.show} onHide={props.handleClose}>
      <Modal.Header>
        <Modal.Title>
          {props.concorrente.nome} - {props.indice}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Row>
            <Col>
              <Form.Label>Numero</Form.Label>
              <Form.Control
                placeholder="numero"
                name="numero"
                type="number"
                value={props.concorrente.numero}
                onChange={(e, indice) => props.handleChange(e, props.indice)}
              />
            </Col>
            <Col>
              <Form.Label>Nome e Cognome</Form.Label>
              <Form.Control
                placeholder="Nome e Cognome"
                name="nome"
                type="text"
                value={props.concorrente.nome}
                onChange={(e, indice) => props.handleChange(e, props.indice)}
              />
            </Col>
          </Row>
          <Row className="my-3">
            <Col>
              <Form.Label>Anno di nascita</Form.Label>
              <Form.Control
                placeholder="Anno di nascita"
                name="anno"
                type="number"
                value={props.concorrente.anno}
                onChange={(e, indice) => props.handleChange(e, props.indice)}
              />
            </Col>
            <Col>
              <Form.Label></Form.Label>
              <br></br>
              {props.concorrente.brevettoCompleto ? (
                <Badge pill variant="primary">
                  Brevetto Completato
                </Badge>
              ) : (
                <BrevettoCompletato
                  indice={props.indice}
                  concorrente={props.concorrente}
                  brevettoCompletato={props.brevettoCompletato}
                ></BrevettoCompletato>
              )}
              <br></br>
              {props.concorrente.concorrenteRitirato ? (
                <Badge pill variant="danger">
                  Concorrente ritirato
                </Badge>
              ) : (
                <ConcorrenteRitirato
                  indice={props.indice}
                  concorrente={props.concorrente}
                  concorrenteRitirato={props.concorrenteRitirato}
                ></ConcorrenteRitirato>
              )}
            </Col>
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          onClick={(indice) => props.salvaConcorrente(props.indice)}
        >
          Salva
        </Button>
        <Button variant="secondary" onClick={props.handleClose}>
          Annulla
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalModificaConcorrente;
