import React, { useState, useEffect } from "react";
import TempoImpiegato from "../Admin/TempoImpiegato";
import { db } from "../Util/db";

const IntestazioneTappa = (props) => {
  const [tappa, setTappa] = useState(0);

  useEffect(() => {
    //console.log("aggiorno tempo tappa - intestazione ", props.up);
    db.ref()
      .child("concorrenti/" + props.concorrente + "/tappe/" + props.indicetappa)
      .get()
      .then((data) => {
        if (data.exists()) {
          //console.log(data.val());
          setTappa(data.val());
        } else {
          console.log("No data available - tappe completate");
          setTappa("");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, [props.concorrente, props.indicetappa, props.up]);

  return (
    <div className={tappa.tempoimpiegato ? "completata" : ""}>
      <h3>{props.nometappa}</h3>
      <TempoImpiegato
        indiceConcorrente={props.concorrente}
        indiceTappa={props.indicetappa}
      ></TempoImpiegato>
    </div>
  );
};

export default IntestazioneTappa;
