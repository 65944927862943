import React, { Fragment } from "react";
import NavAdmin from "./NavAdmin";
import TabellaConcorrenti from "./TabellaConcorrenti";
import { Switch, Route, Redirect } from "react-router-dom";
import GestioneTappe from "./GestioneTappe";

const AdminPage = (props) => {
  return (
    <Fragment>
      <NavAdmin user={props.user}></NavAdmin>
      <Switch>
        <Route path="/admin/concorrenti">
          <TabellaConcorrenti></TabellaConcorrenti>
        </Route>
        <Route path="/admin/tappe">
          <GestioneTappe></GestioneTappe>
        </Route>
        <Route path="/admin">
          <Redirect to="/admin/concorrenti"></Redirect>
        </Route>
      </Switch>
    </Fragment>
  );
};

export default AdminPage;
