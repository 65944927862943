import { db } from "./db";

export function salvaDatiControllo(
  tipo,
  tappa,
  controllo,
  concorrente,
  timestamp,
  nometappa,
  nomeControllo
) {
  console.log(
    "salvo controllo : " +
      tipo +
      " - " +
      tappa +
      " - " +
      controllo +
      " - " +
      concorrente +
      " - " +
      timestamp
  );
  let query;
  let queryNomeTappa;
  switch (tipo) {
    case "partenza":
      query = "/concorrenti/" + concorrente + "/tappe/" + tappa + "/partenza/";
      queryNomeTappa = "/concorrenti/" + concorrente + "/tappe/" + tappa;
      break;
    case "arrivo":
      query = "/concorrenti/" + concorrente + "/tappe/" + tappa + "/arrivo/";
      break;
    case "controllo":
      query =
        "/concorrenti/" +
        concorrente +
        "/tappe/" +
        tappa +
        "/controlli/" +
        controllo;
      break;
    default:
  }

  db.ref(query)
    .update({
      nomeControllo: nomeControllo,
      orarioPassaggio: timestamp,
    })
    .catch((error) => {
      console.error(error);
    });

  if (nometappa) {
    db.ref(queryNomeTappa)
      .update({
        nome: nometappa,
      })
      .catch((error) => {
        console.error(error);
      });
  }
}
