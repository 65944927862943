import React from "react";
import { Container, Navbar, NavDropdown, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import { auth } from "../Util/db";

const NavAdmin = (props) => {
  function logout() {
    auth.signOut();
  }

  return (
    <Navbar
      sticky="top"
      bg="dark"
      variant="dark"
      className="justify-content-between"
    >
      <Container>
        <Navbar.Brand href="#home" className="ml-5">
          VEB {new Date().getFullYear()}
        </Navbar.Brand>
        <Nav className="me-auto">
          <Link className="linkadmin mx-2" to="/admin/concorrenti">
            Gestione Concorrenti
          </Link>
          <Link className="linkadmin mx-2" to="/concorrenti">
            Stato Concorrenti
          </Link>
          <Link className="linkadmin mx-2" to="/admin/tappe">
            Gestione Tappe
          </Link>
          <Link className="linkadmin mx-2" to="/">
            VEB APP (Frontend)
          </Link>
        </Nav>
        <NavDropdown
          className="nomeconcorrente"
          title={props.user.email}
          id="basic-nav-dropdown"
        >
          <NavDropdown.Item onClick={() => logout()}>Esci</NavDropdown.Item>
        </NavDropdown>
      </Container>
    </Navbar>
  );
};

export default NavAdmin;
