import React, { useEffect, useState } from "react";
import { db } from "../Util/db";
import QRControllo from "./QRControllo";

const ListaControlliAdmin = (props) => {
  const [controlli, setControlli] = useState([]);

  useEffect(() => {
    db.ref()
      .child("/config/tappe/" + props.indiceTappa + "/controlli/")
      .get()
      .then((data) => {
        if (data.exists()) {
          console.log(data.val());
          setControlli(data.val());
        } else {
          console.log("No data available - controlli");
          setControlli([]);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, [props.indiceTappa]);

  //return <h1>ss {props.indiceTappa}</h1>;
  return controlli.map((controllo, indice) => (
    <QRControllo
      indice={indice}
      controllo={controllo}
      indiceTappa={props.indiceTappa}
    ></QRControllo>
  ));
};

export default ListaControlliAdmin;
