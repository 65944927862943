import React, { useEffect, useState } from "react";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { faCheckSquare, faCoffee } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { db } from "../Util/db";
import { Badge } from "react-bootstrap";

library.add(fas, faCheckSquare, faCoffee);

const TempoImpiegato = (props) => {
  const [tempoImpiegato, setTempoImpiegato] = useState(0);

  useEffect(() => {
    aggiornaTempo();
  });

  function aggiornaTempo() {
    console.log("aggiorno tempo");
    db.ref()
      .child(
        "concorrenti/" + props.indiceConcorrente + "/tappe/" + props.indiceTappa
      )
      .get()
      .then((data) => {
        if (data.exists()) {
          //console.log(data.val());
          setTempoImpiegato(data.val().tempoimpiegato);
        } else {
          console.log("No data available - tempo impiegato tappa");
          //setConcorrenti([]);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  return (
    <div className="tempoimpiegato mb-3">
      <Badge pill variant="primary">
        {tempoImpiegato
          ? "Tempo impiegato: " + tempoImpiegato
          : "da completare "}
        &nbsp;
        <FontAwesomeIcon onClick={aggiornaTempo} icon="sync" />
      </Badge>
    </div>
  );
};

export default TempoImpiegato;
