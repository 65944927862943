import React from "react";
import { Card, Form, Button } from "react-bootstrap";
//import ControlloQrCode from "./ControlloQrCode";

const Login = (props) => {
  return (
    <Card className="px-3 py-3">
      <Card.Title>Accedi</Card.Title>
      <small>
        inserisci il tuo numero di pettorale e il tuo anno di nascita
      </small>
      <Form onSubmit={(event) => props.handleSubmit(event)}>
        <Form.Group controlId="formBasic">
          <Form.Control
            className="my-2"
            name="numero"
            type="number"
            placeholder="Numero di pettorale"
            value={props.concorrente.numero}
            onChange={(event) => props.handleChange(event)}
          />
          <Form.Control
            className="my-2"
            name="anno"
            type="number"
            placeholder="Anno di nascita (AAAA)"
            value={props.concorrente.anno}
            onChange={(event) => props.handleChange(event)}
          />
        </Form.Group>
        <Button variant="primary" className="mt-2" type="submit">
          Invia
        </Button>
        <br></br>
        v1.03
      </Form>
    </Card>
  );
};

export default Login;
