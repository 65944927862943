import React, { useState, useEffect } from "react";
import { Table, Row, Col, Badge, Container } from "react-bootstrap";
import { db } from "../Util/db";
import UltimoControllo from "../Admin/UltimoControllo";
import Orologio from "./Orologio";
import UltimaTappa from "./UltimaTappa";

const ListaConcorrenti = () => {
  const [concorrenti, setConcorrenti] = useState([]);

  useEffect(() => {
    db.ref("concorrenti/")
      .orderByChild("ultimoaggiornamento")
      .limitToLast(30)
      .on("value", (snapshot) => {
        let concorrentiN = [];
        snapshot.forEach(function (concorrente, indice) {
          //console.log(concorrente.key);
          let concorrenteN = concorrente.val();
          concorrenteN.indice = concorrente.key;
          concorrentiN.push(concorrenteN);
        });
        setConcorrenti(concorrentiN.reverse());
      });
  }, []);
  return (
    <Container className="maincontainer">
      <Row className="mt-5 mb-3">
        <Col lg={7}>
          <Orologio></Orologio>
          <h1>Ultimi aggiornamenti - VEB 2021</h1>
        </Col>
        <Col lg={2}></Col>
        <Col lg={3}>{/* <OrologioAnalogico></OrologioAnalogico> */}</Col>
      </Row>
      <Table striped bordered hover className="listaconcorrenti">
        <thead>
          <tr>
            <th>Pettorale</th>
            <th>Nome e Cognome</th>
            <th>Ultimo Controllo Completato</th>
            <th>Ultima Tappa Completata</th>
            <th>Stato</th>
          </tr>
        </thead>
        <tbody>
          {concorrenti.map((concorrente) => (
            <tr key={concorrente.indice}>
              <td>{concorrente.numero}</td>
              <td>{concorrente.nome}</td>
              <td>
                <UltimoControllo
                  indiceConcorrente={concorrente.indice}
                ></UltimoControllo>
              </td>
              <td>
                <UltimaTappa
                  indiceConcorrente={concorrente.indice}
                ></UltimaTappa>
              </td>
              <td>
                {concorrente.concorrenteRitirato ? (
                  <Badge variant="danger">Ritirato</Badge>
                ) : concorrente.brevettoCompleto ? (
                  <Badge variant="success">Brevetto completato</Badge>
                ) : (
                  <Badge variant="primary">In viaggio</Badge>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      * vengono visualizzati solo gli ultimi 30 concorrenti con aggiornamenti
    </Container>
  );
};

export default ListaConcorrenti;
