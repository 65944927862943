import React, { useEffect, useState } from "react";
import { db } from "../Util/db";
import DataCustom from "../Components/DataCustom";

const UltimoControllo = (props) => {
  const [ultimoControllo, setUltimoControllo] = useState("");
  useEffect(() => {
    db.ref()
      .child("/concorrenti/" + props.indiceConcorrente + "/ultimocontrollo/")
      .on("value", (data) => {
        if (data.exists()) {
          //console.log("i", props.indiceConcorrente, data.val());
          setUltimoControllo(data.val());
        } else {
          console.log("No data available - ultimo controllo");
          setUltimoControllo("");
        }
      });
  }, [props.indiceConcorrente]);

  return ultimoControllo.orarioPassaggio ? (
    <b>
      <small>
        {ultimoControllo.nomeControllo} -
        <DataCustom data={ultimoControllo.orarioPassaggio}></DataCustom>
      </small>
    </b>
  ) : (
    ""
  );
};

export default UltimoControllo;
