import React, { useEffect, useState, Fragment } from "react";
import { db } from "../Util/db";

const UltimaTappa = (props) => {
  const [ultimaTappa, setultimaTappa] = useState("");
  useEffect(() => {
    db.ref()
      .child("/concorrenti/" + props.indiceConcorrente + "/tappe/")
      .on("value", (snapshot) => {
        if (snapshot.exists()) {
          let ultimatappaN = {};
          snapshot.forEach(function (tappa) {
            if (tappa.val().tempoimpiegato) {
              //console.log(props.indiceConcorrente, tappa.val());
              ultimatappaN = tappa.val();
            }
          });
          setultimaTappa(ultimatappaN);
        }
      });
  }, [props.indiceConcorrente]);

  return ultimaTappa.nome ? (
    <Fragment>
      <small>
        {ultimaTappa.nome}
        <br></br>
        <br></br>con il tempo di : {ultimaTappa.tempoimpiegato}
      </small>
    </Fragment>
  ) : (
    ""
  );
};

export default UltimaTappa;
