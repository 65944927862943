import React, { Component } from "react";
import { Row, Col, Accordion, Card } from "react-bootstrap";
import { db } from "../Util/db";
import Controllo from "./Controllo";
import IntestazioneTappa from "./IntestazioneTappa";
import ListaControlli from "./ListaControlli";

class PannelloTappe extends Component {
  state = { tappe: [], up: 0 };

  componentDidMount() {
    db.ref()
      .child("config/tappe/")
      .get()
      .then((data) => {
        if (data.exists()) {
          //console.log(data.val());
          this.setState({ tappe: data.val() });
        } else {
          console.log("No data available");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  //serve per aggiornare gli altri componenti della pagina
  passaggio() {
    this.setState({ up: Math.random() });
  }

  render() {
    return (
      <div className="mt-5">
        <Accordion>
          {this.state.tappe.map((tappa, indice) => (
            <Card key={tappa.id}>
              <Card.Header>
                <Accordion.Toggle
                  as={Card.Header}
                  variant="link"
                  eventKey={tappa.id}
                >
                  <IntestazioneTappa
                    up={this.state.up}
                    nometappa={tappa.nome}
                    indicetappa={indice}
                    concorrente={this.props.concorrente.key}
                  ></IntestazioneTappa>
                </Accordion.Toggle>
              </Card.Header>
              <Card.Body className="controllitappa">
                <Accordion.Collapse eventKey={tappa.id}>
                  <Row>
                    <Col sm={12}></Col>
                    <Col sm={12}>
                      <Controllo
                        tipo="partenza"
                        idtappa={tappa.id}
                        indicetappa={indice}
                        nometappa={tappa.nome}
                        concorrente={this.props.concorrente.key}
                        passaggio={() => this.passaggio()}
                      ></Controllo>
                    </Col>
                    <Col sm={12}>
                      <ListaControlli
                        idtappa={tappa.id}
                        indicetappa={indice}
                        controlli={tappa.controlli}
                        concorrente={this.props.concorrente.key}
                        passaggio={() => this.passaggio()}
                      ></ListaControlli>
                    </Col>
                    <Col sm={12}>
                      <Controllo
                        tipo="arrivo"
                        idtappa={tappa.id}
                        indicetappa={indice}
                        concorrente={this.props.concorrente.key}
                        passaggio={() => this.passaggio()}
                      ></Controllo>
                    </Col>
                  </Row>
                </Accordion.Collapse>
              </Card.Body>
            </Card>
          ))}
        </Accordion>
      </div>
    );
  }
}

export default PannelloTappe;
