import React from "react";
import Controllo from "./Controllo";

const ListaControlli = (props) => {
  return props.controlli.map((controllo, indice) => (
    <Controllo
      key={controllo.id}
      tipo="controllo"
      idtappa={props.idtappa}
      indicetappa={props.indicetappa}
      indicecontrollo={indice}
      nomecontrollo={controllo.nome}
      concorrente={props.concorrente}
      passaggio={props.passaggio}
    ></Controllo>
  ));
};

export default ListaControlli;
