import React, { useEffect, useState, Fragment } from "react";
import { Button, Modal, Form, Col, Row } from "react-bootstrap";
import { db } from "../Util/db";
import ModificaControllo from "./ModificaControllo";
import TempoImpiegato from "./TempoImpiegato";

const ModalModificaTappeConcorrente = (props) => {
  const [tappe, setTappe] = useState(false);

  //carico lista tappe completa
  useEffect(() => {
    db.ref()
      .child("config/tappe")
      .get()
      .then((data) => {
        if (data.exists()) {
          //console.log(data.val());
          setTappe(data.val());
        } else {
          console.log("No data available - concorrenti");
          setTappe([]);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  function getOrarioPassaggio(
    indiceConcorrente,
    indiceTappa,
    indiceControllo,
    tipo
  ) {
    if (props.concorrente.tappe != null) {
      if (props.concorrente.tappe[indiceTappa] != null) {
        if (
          tipo === "partenza" &&
          props.concorrente.tappe[indiceTappa].partenza
        ) {
          return props.concorrente.tappe[indiceTappa].partenza.orarioPassaggio;
        } else if (
          tipo === "arrivo" &&
          props.concorrente.tappe[indiceTappa].arrivo
        ) {
          return props.concorrente.tappe[indiceTappa].arrivo.orarioPassaggio;
        } else if (
          tipo === "controllo" &&
          props.concorrente.tappe[indiceTappa].controlli &&
          props.concorrente.tappe[indiceTappa].controlli[indiceControllo]
        ) {
          return props.concorrente.tappe[indiceTappa].controlli[indiceControllo]
            .orarioPassaggio;
        } else {
          return 0;
        }
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  }

  return (
    <Modal
      size="lg"
      dialogClassName="modaletappe"
      show={props.show}
      onHide={props.handleClose}
    >
      <Modal.Header>
        <Modal.Title>
          {props.concorrente.nome} - {props.indice}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Row>
            {tappe
              ? tappe.map((tappa, indiceTappa) => (
                  <Fragment key={indiceTappa + 4}>
                    <Row>
                      <Col>
                        <h4>{tappa.nome} - </h4>
                        <TempoImpiegato
                          indiceConcorrente={props.indice}
                          indiceTappa={indiceTappa}
                        ></TempoImpiegato>
                      </Col>
                    </Row>
                    <Row key={indiceTappa}>
                      {tappa.partenza ? (
                        <Col key={indiceTappa}>
                          <Form.Label>{tappa.partenza.nome}</Form.Label>
                          <ModificaControllo
                            orarioPassaggio={getOrarioPassaggio(
                              props.indice,
                              indiceTappa,
                              0,
                              "partenza"
                            )}
                            nomeTappa={tappa.nome}
                            nomeControllo={tappa.partenza.nome}
                            indiceTappa={indiceTappa}
                            indiceControllo={false}
                            indiceConcorrente={props.indice}
                            aggiungiPassaggio={props.aggiungiPassaggio}
                            handleChangeControllo={props.handleChangeControllo}
                            tipo="partenza"
                          ></ModificaControllo>
                        </Col>
                      ) : (
                        ""
                      )}
                      {tappa.controlli ? (
                        <Col key={indiceTappa + 1}>
                          {tappa.controlli.map((controllo, indiceControllo) => (
                            <Fragment key={indiceControllo}>
                              <Form.Label>
                                Controllo {controllo.nome}
                              </Form.Label>
                              <ModificaControllo
                                orarioPassaggio={getOrarioPassaggio(
                                  props.indice,
                                  indiceTappa,
                                  indiceControllo,
                                  "controllo"
                                )}
                                nomeTappa={tappa.nome}
                                nomeControllo={controllo.nome}
                                indiceTappa={indiceTappa}
                                indiceConcorrente={props.indice}
                                indiceControllo={indiceControllo}
                                aggiungiPassaggio={props.aggiungiPassaggio}
                                handleChangeControllo={
                                  props.handleChangeControllo
                                }
                                tipo="controllo"
                              ></ModificaControllo>
                            </Fragment>
                          ))}
                        </Col>
                      ) : (
                        ""
                      )}
                      {tappa.arrivo ? (
                        <Col key={indiceTappa + 2}>
                          <Form.Label>{tappa.arrivo.nome}</Form.Label>
                          <ModificaControllo
                            orarioPassaggio={getOrarioPassaggio(
                              props.indice,
                              indiceTappa,
                              0,
                              "arrivo"
                            )}
                            nomeTappa={tappa.nome}
                            nomeControllo={tappa.arrivo.nome}
                            indiceTappa={indiceTappa}
                            indiceControllo={false}
                            indiceConcorrente={props.indice}
                            aggiungiPassaggio={props.aggiungiPassaggio}
                            handleChangeControllo={props.handleChangeControllo}
                            tipo="arrivo"
                          ></ModificaControllo>
                        </Col>
                      ) : (
                        ""
                      )}
                    </Row>
                    <Row>
                      <Col>
                        <hr />
                      </Col>
                    </Row>
                  </Fragment>
                ))
              : "nessuna tappa completate"}
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          onClick={(indice) => props.salvaConcorrente(props.indice)}
        >
          Salva
        </Button>
        <Button variant="secondary" onClick={props.handleClose}>
          Annulla
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalModificaTappeConcorrente;
