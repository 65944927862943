import "./App.css";
import React, { Component } from "react";
import Login from "./Components/Login";
import Cookies from "universal-cookie";
import PannelloTappe from "./Components/PannelloTappe";
import {
  Container,
  Row,
  Col,
  Navbar,
  NavDropdown,
  Alert,
} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
//import Orologio from "./Components/Orologio";
//import OrologioAnalogico from "./Components/OrologioAnalogico";
import { Switch, Route } from "react-router-dom";
import MainAdmin from "./Admin/MainAdmin";
import { db } from "./Util/db";
import ListaConcorrenti from "./Components/ListaConcorrenti";

class App extends Component {
  //state = { concorrente: { numero: "2", anno: "1958" } };
  state = { concorrente: {}, errore: "" };

  handleSubmit(event) {
    event.preventDefault();
    //console.log(this.state.concorrente.numero);
    //verifico se l'anno corrisponde
    if (this.state.concorrente.numero) {
      db.ref()
        .child("concorrenti")
        .orderByChild("numero")
        .equalTo(this.state.concorrente.numero)
        .limitToFirst(1)
        .on("value", (data) => {
          if (data.exists()) {
            data.forEach((conco) => {
              console.log(conco.key);
              if (conco.val().anno === this.state.concorrente.anno) {
                const concorrente = { ...this.state.concorrente };
                concorrente.nome = conco.val().nome;
                concorrente.key = conco.key;

                //imposto data di scadenza cookie 1 mese
                let data = new Date();
                data.setMonth(data.getMonth() + 1);

                const cookies = new Cookies();
                cookies.set("key", conco.key, {
                  path: "/",
                  expires: data,
                });
                cookies.set("nome", conco.val().nome, {
                  path: "/",
                  expires: data,
                });
                cookies.set("anno", conco.val().anno, {
                  path: "/",
                  expires: data,
                });
                cookies.set("numero", conco.val().numero, {
                  path: "/",
                  expires: data,
                });
                this.setState({ concorrente });
                //salva cookie
              } else {
                this.setState({
                  errore: "Controlla il numero e l'anno di nascita",
                });
              }
            });
          } else {
            console.log("No data available - login utente");
            return false;
          }
        });

      //console.log(this.state.concorrente.anno);
    }
    //this.props.onHandleSubmit(this.state.concorrente);
  }

  handleChange(event) {
    let concorrente = { ...this.state.concorrente };
    concorrente[event.target.name] = event.target.value;
    this.setState({ concorrente });
  }

  logout() {
    const cookies = new Cookies();
    cookies.remove("key");
    cookies.remove("numero");
    cookies.remove("nome");
    cookies.remove("anno");
    this.setState({ concorrente: "" });
    //cancello il cookie
  }

  componentDidMount() {
    //verifico il cookie
    const cookies = new Cookies();
    let cookie = cookies.get("key");
    if (cookie) {
      let conco = {};
      console.log("cookie ok - ", cookies.get("key"));

      conco.key = cookies.get("key");
      conco.numero = cookies.get("numero");
      conco.anno = cookies.get("anno");
      conco.nome = cookies.get("nome");
      this.setState({ concorrente: conco });
    }
  }

  render() {
    return (
      <div className="App">
        <Switch>
          <Route path="/admin">
            <MainAdmin></MainAdmin>
          </Route>
          <Route path="/concorrenti">
            <ListaConcorrenti></ListaConcorrenti>
          </Route>
          <Route exact path="/">
            <Navbar
              sticky="top"
              bg="dark"
              variant="dark"
              className="justify-content-between"
            >
              <Container>
                <Navbar.Brand href="#home" className="ml-5">
                  VEB {new Date().getFullYear()}
                </Navbar.Brand>
                {this.state.concorrente.nome ? (
                  <NavDropdown
                    className="nomeconcorrente"
                    title={this.state.concorrente.nome}
                    id="basic-nav-dropdown"
                  >
                    <NavDropdown.Item onClick={() => this.logout()}>
                      Esci
                    </NavDropdown.Item>
                  </NavDropdown>
                ) : (
                  ""
                )}
              </Container>
            </Navbar>

            {!this.state.concorrente.key ? (
              <Row className="mt-5">
                <Col md={4} xs={2}>
                  {/* <Orologio></Orologio> */}
                </Col>
                <Col md={4} xs={8} className="mb-5">
                  <Login
                    concorrente={this.state.concorrente}
                    handleChange={(event) => this.handleChange(event)}
                    handleSubmit={(event) => this.handleSubmit(event)}
                  ></Login>
                  {this.state.errore ? (
                    <Alert variant="danger">{this.state.errore}</Alert>
                  ) : (
                    ""
                  )}
                </Col>
                <Col md={4} xs={2}>
                  {/* <OrologioAnalogico></OrologioAnalogico> */}
                </Col>
              </Row>
            ) : (
              <Container className="maincontainer mt-4">
                <Row>
                  <Col>
                    {this.state.concorrente.key && (
                      <PannelloTappe
                        concorrente={this.state.concorrente}
                      ></PannelloTappe>
                    )}
                  </Col>
                </Row>
              </Container>
            )}
          </Route>
        </Switch>
      </div>
    );
  }
}

export default App;
