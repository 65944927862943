import React, { Component } from "react";
import { auth } from "../Util/db";
import { Alert, Button, Col, Form, Row, Container } from "react-bootstrap";

//774kSSPS&x

class LoginAdmin extends Component {
  state = {
    loading: true,
    user: {
      username: "",
      password: "",
    },
    error: "",
  };

  componentDidMount() {
    this.setState({ loading: false });
    //se attivo il cookie ritorno subito autenticato
  }

  onSubmit(e) {
    e.preventDefault();
    auth
      .signInWithEmailAndPassword(
        this.state.user.username,
        this.state.user.password
      )
      .then((res) => {
        console.log("Loggato", res);
        //this.props.onAutenticato = true;
      })
      .catch((error) => {
        this.setState({ error: error.message });
        console.log(error);
      });
  }

  handleChange(e) {
    let user = { ...this.state.user };
    user[e.target.name] = e.target.value;
    this.setState({ user });
  }

  render() {
    return this.state.loading ? (
      "loading"
    ) : (
      <Row className="my-5">
        <Col md={4} xs={1}></Col>
        <Col md={4} xs={10}>
          <Container className="maincontainer py-5 px-5">
            {this.state.error ? (
              <Alert variant="danger" severity="error">
                {this.state.error}
              </Alert>
            ) : null}
            <Form noValidate onSubmit={(e) => this.onSubmit(e)}>
              <Form.Group>
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  id="username"
                  name="username"
                  placeholder="Email "
                  onChange={(e) => this.handleChange(e)}
                />
                <Form.Text className="text-muted"></Form.Text>
              </Form.Group>

              <Form.Group className="my-2">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  id="password"
                  name="password"
                  placeholder="Password"
                  onChange={(e) => this.handleChange(e)}
                />
              </Form.Group>
              <Button className="my-2" variant="primary" type="submit">
                Login
              </Button>
            </Form>
          </Container>
        </Col>
        <Col md={4} xs={1}></Col>
      </Row>
    );
  }
}

export default LoginAdmin;
